import classNames from "classnames";
import React, { Fragment } from "react";
import { isNativeIOS } from "utils/devices";

const App = (props) => {
  return (
    <Fragment>
      <main
        className={classNames(`app bg-${props.bg} color-${props.bg}`, {
          "ios-native-platform": isNativeIOS,
        })}
      >
        <div className="app__container">{props.children}</div>
      </main>
    </Fragment>
  );
};

export default App;
